import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

//Customised services
import { GetDataService } from './get-data.service';

@Injectable({
    providedIn: 'root'
})

export class ProductsService {

    all_products;
    root_url = 'http://34.92.91.7:80';
    
    constructor( public DataService: GetDataService ) { 
        this.DataService.get_all_products().subscribe(data=>{
            this.all_products = data;
        })
    }
    
    getAllProducts() {
        return this.DataService.get_products();
    }
    
    getProduct(code: string) {
        return this.all_products.find( e => e.product_code === code )
    }
}
