import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";

// Translation
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { ProductsComponent } from "./products/products.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ProductsDetailsComponent } from "./products/products-details.component";
import { ProductsCategoryComponent } from "./products/products-category.component";
import { WishlistComponent } from "./wishlist/wishlist.component";
import { WishlistThankyouComponent } from "./wishlist/wishlist-thankyou.component";
import { TncPageComponent } from "./tnc-page/tnc-page.component";
import { PrivacyPageComponent } from "./privacy-page/privacy-page.component";

import {
  ProductFilterEnPipe,
  ProductFilterZhPipe,
  categroyFilterPipe,
  subCategroyFilterPipe,
} from "./custom-pipes/product-filter.pipe";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Angular Materials
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ProductsComponent,
    AboutUsComponent,
    ContactUsComponent,
    ProductsDetailsComponent,
    ProductsCategoryComponent,
    ProductFilterEnPipe,
    ProductFilterZhPipe,
    categroyFilterPipe,
    subCategroyFilterPipe,
    WishlistComponent,
    WishlistThankyouComponent,
    TncPageComponent,
    PrivacyPageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
  ],
  exports: [TranslateModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
