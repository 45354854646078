import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductsComponent } from './products/products.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProductsDetailsComponent } from './products/products-details.component';
import { ProductsCategoryComponent } from './products/products-category.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { WishlistThankyouComponent } from './wishlist/wishlist-thankyou.component';
import { TncPageComponent } from './tnc-page/tnc-page.component';
import { PrivacyPageComponent } from './privacy-page/privacy-page.component';

const appRoutes: Routes = [
    { path: 'home', component: HomePageComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'product/:code', component: ProductsDetailsComponent },
    { path: 'products/:cat/:sub', component: ProductsCategoryComponent },
    { path: 'about', component: AboutUsComponent },
    { path: 'contact', component: ContactUsComponent },
    { path: 'wishlist', component: WishlistComponent },
    { path: 'wishlist-thanks', component: WishlistThankyouComponent },
    { path: 'terms-conditions', component: TncPageComponent },
    { path: 'privacy-policy', component: PrivacyPageComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
]

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            { useHash: true, scrollPositionRestoration: 'enabled' } //
        ),
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
