import { Component, OnInit } from '@angular/core';

import { LanguageService} from '../language.service';
import { Subscription } from 'rxjs';
import { GetDataService} from '../get-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-tnc-page',
    templateUrl: './tnc-page.component.html',
    styleUrls: ['./tnc-page.component.css']
})
export class TncPageComponent implements OnInit {

    subscription:Subscription;
    lang;
    htmlContent;
    htmlContentZH;
    htmlContentEN;

    constructor( private DataService: GetDataService, public LangService: LanguageService, private translate: TranslateService ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)

        this.DataService.get_tnc().subscribe(data => {
            this.htmlContent = data[0];
            this.htmlContentZH = this.htmlContent.content.zh;
            this.htmlContentEN = this.htmlContent.content.en;
        })
    }

}
