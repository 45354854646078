// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view,body,html {
    height:100%
}

.the_logo {
    height: 8vh;
}

.mainCatTitle{
    font-weight: bold;
}

.navbar {
/*    background-color: #ffffff;*/
    color: black !important;
}
.page-footer,.top-nav-collapse {
    background-color: #5F7990
}

.navbar-nav a {
    border-bottom: transparent 0px solid;
    transition: border-bottom 0.5s ease-in;
}

.navbar-nav li:hover {
    color: darkgrey !important;
}
.navbar-nav .active a{
    background-color: transparent !important;
    color: #5F7990;
    font-weight: bold;
    border-bottom: #5F7990 2px solid !important;
}

.dropdown-menu {
    width: 60vw !important;
    max-height: 80vh !important;
    transform: translateX(-10vw);
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

a:link {
  color: #000000;
}

/* visited link */
a:visited {
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;AACA,iCAAiC;IAC7B,uBAAuB;AAC3B;AACA;IACI;AACJ;;AAEA;IACI,oCAAoC;IACpC,sCAAsC;AAC1C;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,wCAAwC;IACxC,cAAc;IACd,iBAAiB;IACjB,2CAA2C;AAC/C;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA,iBAAiB;AACjB;EACE,cAAc;AAChB","sourcesContent":[".view,body,html {\n    height:100%\n}\n\n.the_logo {\n    height: 8vh;\n}\n\n.mainCatTitle{\n    font-weight: bold;\n}\n\n.navbar {\n/*    background-color: #ffffff;*/\n    color: black !important;\n}\n.page-footer,.top-nav-collapse {\n    background-color: #5F7990\n}\n\n.navbar-nav a {\n    border-bottom: transparent 0px solid;\n    transition: border-bottom 0.5s ease-in;\n}\n\n.navbar-nav li:hover {\n    color: darkgrey !important;\n}\n.navbar-nav .active a{\n    background-color: transparent !important;\n    color: #5F7990;\n    font-weight: bold;\n    border-bottom: #5F7990 2px solid !important;\n}\n\n.dropdown-menu {\n    width: 60vw !important;\n    max-height: 80vh !important;\n    transform: translateX(-10vw);\n}\n\n.avatar {\n  vertical-align: middle;\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n}\n\na:link {\n  color: #000000;\n}\n\n/* visited link */\na:visited {\n  color: #000000;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
