import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

// Customised services
import { GetDataService } from './get-data.service';

@Injectable({
    providedIn: 'root'
})
export class WishListService {

    // start of variables declaration
    all_products;
    products_list = [];
    wishlist = [];
    // end of variables declaration

    constructor( public dataService: GetDataService ) {
        this.dataService.get_products().subscribe( data => {
            this.all_products = data;
        })
    }

    addToWishlist(product) {
        if ( this.products_list.includes(product)) {
            return this.products_list;
        } else {
            this.products_list.push(product);
            var _addedItem = this.all_products.find( ({ product_code }) => product_code === product );
            this.wishlist.push(_addedItem);
        }
    }

    getWishlist() {
        return this.products_list;
    }

    getWholeWishlist(): Observable<any> {
        return of(this.wishlist);
    }

    clearWishlist(){
        this.products_list = [];
        this.wishlist = [];
    }

    removeFromWishlist (product) {
        this.products_list.forEach ( (p, i) => {
            if ( p === product ) {
                this.products_list.splice(i, 1);
            }
        });
        this.wishlist.forEach ( (p, i) => {
            if ( p.product_code === product ) {
                this.wishlist.splice(i, 1);
            }
        })
    }
}
