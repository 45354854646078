import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// Customised services
import { GetDataService } from '../get-data.service';
import { ProductsService } from '../products.service';
import { WishListService } from '../wish-list.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

@Component({
    selector: 'app-products-category',
    templateUrl: './products-category.component.html',
    styleUrls: ['./products-category.component.css']
})
export class ProductsCategoryComponent implements OnInit {

    // start of variables declaration
    _code: string;
    all_products;
    category: string;
    searchTerm: string;
    wishlist;
    subCatList: any;
    mainCatTerm;
    subCatTerm;
    // end of variables declaration

    // Translation declaration
    lang;
    subscription: Subscription;



    // pictures root link
    graphics_url = this.dataService.getRoot();

    constructor( public dataService: GetDataService, public productsService: ProductsService, public wishlistService: WishListService, private translate: TranslateService, public LangService: LanguageService,    private _route: ActivatedRoute, private _router: Router ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);

        this.dataService.get_products().subscribe(data=>{
            this.all_products = data;
        });

        this.all_products = this.productsService.getAllProducts();
        this._route.paramMap.subscribe( params => {
            this.mainCatTerm = this._route.snapshot.queryParamMap.get('mainCatCodeTerm');
            this.subCatTerm = this._route.snapshot.queryParamMap.get('subCatCodeTerm');
            this.dataService.getSubCatList( { mainCatCode: this.mainCatTerm } ).subscribe( data => {
                if ( this.subCatTerm == undefined || this.subCatTerm == '' ) {
                    this._code = params.get('cat');
                    this.mainCatTerm = this._code;
                    this.subCatTerm = 'all';
                    this.subCatList = data;
                } else { 
                    this.subCatList = [];
                    this.subCatList.push(this.subCatTerm)
                }
            })
        })
        this.wishlist = this.wishlistService.getWishlist();
    }

    viewProduct(productCode: string) {
        this._router.navigate(['/product', productCode])
    }

    get_product_small_photo(code) {
        return this.graphics_url + '/small_size/' + code + '.png';
    }

    get_wholesale_price(product) {
        return product.wholesale_unit_price * product.wholesale_min_order_quantity
    }

    displayWishlistItems(code) {
        return this.wishlist.includes(code);
    }
    addtoWishlist(code) {
        this.wishlistService.addToWishlist(code);
    }
    //
    removefromWishlist(code) {
        this.wishlistService.removeFromWishlist(code);
    }

    scroll(id) {
        const itemToScrollTo = document.getElementById(id);
        // null check to ensure that the element actually exists
        if (itemToScrollTo) {
            itemToScrollTo.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
    }

}   
