import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'categoryFilter'
})

export class categroyFilterPipe implements PipeTransform {
    transform( products, searchTerm: string) {
        if (!products || !searchTerm  ) {
            return products;
        }
        return products.filter( product => product.mainCatCode.indexOf(searchTerm) !== -1)
    }
}

@Pipe({
    name: 'subCategoryFilter'
})

export class subCategroyFilterPipe implements PipeTransform {
    transform( products, searchTerm: string) {
        if ( !products || !searchTerm || searchTerm == 'all') {
            return products;
        }
        return products.filter( product => product.subCatCode.indexOf(searchTerm) !== -1)
    }
}

@Pipe({
    name: 'productFilterEn'
})

export class ProductFilterEnPipe implements PipeTransform {
    transform( products, searchTerm: string) {
        if (!products || !searchTerm) {
            return products;
        }

        return products.filter( product => product.product_name.en.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
    }
}

@Pipe({
    name: 'productFilterZh'
})

export class ProductFilterZhPipe implements PipeTransform {
    transform( products, searchTerm: string) {
        if (!products || !searchTerm) {
            return products;
        }

        return products.filter( product => product.product_name.zh.indexOf(searchTerm) !== -1)
    }
}

