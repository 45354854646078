// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    min-height: 100vh;
    font-family: 'Noto Sans', sans-serif;
}

body p {
    font-size: 1.3rem;
}

body span {
    font-size: 1.3rem;
}

.vertical_text-lr {
    writing-mode: vertical-lr;
/*    text-orientation: upright;*/
}

.vertical_text-rl {
    writing-mode: vertical-rl;
/*    text-orientation: upright;*/
}

.cloud_icon {
    min-width: 1.5em;
    height: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/about-us/about-us.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B,iCAAiC;AACjC;;AAEA;IACI,yBAAyB;AAC7B,iCAAiC;AACjC;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB","sourcesContent":["body {\n    min-height: 100vh;\n    font-family: 'Noto Sans', sans-serif;\n}\n\nbody p {\n    font-size: 1.3rem;\n}\n\nbody span {\n    font-size: 1.3rem;\n}\n\n.vertical_text-lr {\n    writing-mode: vertical-lr;\n/*    text-orientation: upright;*/\n}\n\n.vertical_text-rl {\n    writing-mode: vertical-rl;\n/*    text-orientation: upright;*/\n}\n\n.cloud_icon {\n    min-width: 1.5em;\n    height: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
