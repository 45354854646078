import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd  } from '@angular/router';

//Customised services
import { GetDataService } from './get-data.service';
import { ProductsService } from './products.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from './language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    
    // Translation related
    lang;
    subscription: Subscription;
    
    //declare variables
    all_products;
    subscribe_email;
    all_category_list;
    scrollToTerm;
    
    graphics_url = this.dataService.getRoot();
    
    constructor( public dataService: GetDataService, public productsService: ProductsService, public translate: TranslateService, public LangService: LanguageService, public _router: Router, public _route: ActivatedRoute ){
        translate.addLangs(['en', 'zh']);
        translate.setDefaultLang('zh');

        const browserLang = translate.getBrowserLang();
        translate.use('zh');
        translate.use(browserLang.match(/en|zh/) ? browserLang : 'zh');
        
        this.subscription = this.LangService.navItem$.subscribe( item => this.lang = item )
        
        this.dataService.getAllCatList().subscribe( data => {
            this.all_category_list = data;
        });
    }
    
    viewCategory(mainProductCat: string,  subProductCat: string) {
        this._router.navigate(['/products', mainProductCat, subProductCat], {
            queryParams: { 'mainCatCodeTerm' : mainProductCat, 'subCatCodeTerm': subProductCat }
        });
    }
    
    currentRoute = this._router.url;
    
    changeLang (lang){
        this.LangService.changeLang()
        if ( this.translate.currentLang == 'en') {
            this.translate.use('zh');
        } else {
            this.translate.use('en');
        }
    }
    
    subscribe(){
         this.dataService.subscribe_newsletter({email: this.subscribe_email}).subscribe(data=>{
            setTimeout( ()=> { alert('Thank you for subscribing us.'); window.location.reload(), 300 })   
         })
    }

}
