import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//Customised services
import { GetDataService } from '../get-data.service';
import { ProductsService } from '../products.service';
import { WishListService } from '../wish-list.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

@Component({
    selector: 'app-products-details',
    templateUrl: './products-details.component.html',
    styleUrls: ['./products-details.component.css']
})
export class ProductsDetailsComponent implements OnInit {
    
    // Translation declaration
    lang;
    subscription: Subscription;
    
    // declare variables
    _code: string;
    product;
    all_products;
    
    graphics_url = this.dataService.getRoot();

    constructor( private dataService: GetDataService, private productsService: ProductsService, private wishlistService: WishListService, private translate: TranslateService, public LangService: LanguageService, private _router: Router, private _route: ActivatedRoute ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);
        
        this.dataService.get_all_products().subscribe(data=>{
            this.all_products = data;
             this._route.paramMap.subscribe( params => {
            this._code = params.get('code');
            this.product = this.getProduct(this._code);
        })
        });
        
        this.dataService.visit_page_record( { page_visited: this._router.url } ).subscribe(data=>{
            
        })
    }
    
    getProduct(code: string) {
        return this.all_products.filter( e => e.product_code === code )
    }

    get_product_small_photo(code) {
        return this.graphics_url + '/small_size/' + code + '.png';
    }

}
