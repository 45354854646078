import { Component, OnInit } from '@angular/core';

//Customised services
import { GetDataService } from '../get-data.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

    aboutUs;

    // Translation declaration
    lang;
    subscription: Subscription;

    constructor( public dataService: GetDataService, private translate: TranslateService, public LangService: LanguageService ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);
        
        this.dataService.get_web_content( { page : 'aboutUs'} ).subscribe(data=>{
            this.aboutUs = data;
            this.aboutUs = this.aboutUs.body;
        })
    }

    getAboutUsIcon(file) {
        return '../../assets/img/homepage/' + file
    }

}
