// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    width: 100%;
    min-height: 60vh;
    background-image: url('wishlist_bg.png');
    background-size: auto;
}

.modal_bg {
    background-color: #5F7990;
    background-size: cover;
}

.modal_complete_bg {
    background: url('Wishlist_Thankyou.png');
    background-size: cover;
}

.cloud_icon {
    max-width: 100%;
/*    vertical-align: middle;*/
}
`, "",{"version":3,"sources":["webpack://./src/app/wishlist/wishlist.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,wCAAkE;IAClE,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,wCAAkE;IAClE,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB,8BAA8B;AAC9B","sourcesContent":["body {\n    width: 100%;\n    min-height: 60vh;\n    background-image: url(\"../../assets/img/wishlist/wishlist_bg.png\");\n    background-size: auto;\n}\n\n.modal_bg {\n    background-color: #5F7990;\n    background-size: cover;\n}\n\n.modal_complete_bg {\n    background: url('../../assets/img/wishlist/Wishlist_Thankyou.png');\n    background-size: cover;\n}\n\n.cloud_icon {\n    max-width: 100%;\n/*    vertical-align: middle;*/\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
