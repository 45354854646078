import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv/ngx-csv';

// Customised services
import { GetDataService } from '../get-data.service';
import { ProductsService } from '../products.service';
import { WishListService } from '../wish-list.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {

    // Translation declaration
    lang;
    subscription: Subscription;

    msg;
    wishlist;
    congrat_msg: boolean = false;
    submitWishlistForm: UntypedFormGroup;
    ref_num;

    graphics_url = this.dataService.getRoot();

    constructor( public dataService: GetDataService, public productsService: ProductsService, public wishlistService: WishListService, private translate: TranslateService, public LangService: LanguageService, private fb: UntypedFormBuilder, private _router: Router ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);

        //        this.dataService.get_list_of_products(this.wishlistService.getWishlist()).subscribe(data=>{
        //            this.wishlist = data;
        //            console.log(data);
        //        })

        this.wishlistService.getWholeWishlist().subscribe( data=> {
            this.wishlist = data;
        })

        this.submitWishlistForm = this.fb.group({
            name: [''],
            contact_num: [''],
            email: [''],
            company_name: [''],
            enquiry_list: ['']
        })
    }

    get_product_small_photo(code) {
        return this.graphics_url + '/small_size/' + code + '.png';
    }

    removefromWishlist(code) {
        console.log(code);
        this.wishlistService.removeFromWishlist(code);

    }

    downloadCSV() {
        var output = [];
        this.wishlist.forEach( e => {
            var item = {
                product_name_chi: e.product_name.zh,
                product_name_en: e.product_name.en,
                product_code: e.product_code,
                country_of_origin: e.country_of_origin
            }
            output.push(item);
        })
        new ngxCsv(output, 'My Report', {showTitle: true});    
    }

    submitWishlist() {
        if (this.wishlist.length != 0) {
            this.submitWishlistForm.patchValue({
                enquiry_list: this.wishlist
            })
            this.dataService.submit_wishlist(this.submitWishlistForm.value).subscribe(data=>{
                console.log(data);
                this.msg = data;
                if (this.msg.code == 'S') {
                this.wishlist = [];
                this.wishlistService.clearWishlist();
                    this.ref_num = this.msg.body.ref_number;
                    this.congrat_msg = true;
                }
            })        
        }
    }
}
