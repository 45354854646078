// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    width: 100%;
    background-image: url('wishlist_bg.png');
    background-size: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/wishlist/wishlist-thankyou.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wCAAkE;IAClE,qBAAqB;AACzB","sourcesContent":["body {\n    width: 100%;\n    background-image: url(\"../../assets/img/wishlist/wishlist_bg.png\");\n    background-size: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
