import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';

//Customised services
import { GetDataService } from '../get-data.service';
import { WishListService } from '../wish-list.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

    // Translation declaration
    lang;
    subscription: Subscription;
    submitMsgForm: UntypedFormGroup;

    //declare variables
    msg;
    all_products;
    wishlist = [];

    graphics_url = this.dataService.getRoot();

    aboutUs;

    constructor( private _router: Router, public dataService: GetDataService, public wishlistService: WishListService, private fb: UntypedFormBuilder, private translate: TranslateService, public LangService: LanguageService ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);
        
        this.dataService.get_products().subscribe(data=>{
            this.all_products = data;
        });

        this.dataService.visit_page_record( {page_visited: this._router.url} ).subscribe(data=>{

        });

        this.dataService.get_web_content( { page : 'aboutUs'} ).subscribe(data=>{
            this.aboutUs = data;
            this.aboutUs = this.aboutUs.body;
        })

        this.wishlist = this.wishlistService.getWishlist();

        this.submitMsgForm = this.fb.group({
            title: ['', [Validators.required]],
            name: ['', [Validators.required]],
            contact_num: [''],
            email: [''],
            company_name: [''],
            message: ['', [Validators.required]]
        })
    }

    submitMessage() {
        this.dataService.submit_message(this.submitMsgForm.value).subscribe(data=>{
            this.msg = data;
            if (this.msg.code == 'S') {
                setTimeout( ()=> { alert("Thanks for letting us know! We'll get back to you shortly."); window.location.reload(), 300 })
            }
        })   
    }

    viewCategory(mainProductCat: string, subProductCat: string) {
        this._router.navigate(['/products', mainProductCat, subProductCat], {
            queryParams: { 'mainCatCodeTerm' : mainProductCat, 'subCatCodeTerm': subProductCat }
        })
    }

    getAboutUsIcon(file) {
        return '../../assets/img/homepage/' + file
    }

    get_product_small_photo(code) {
        return this.graphics_url + '/small_size/' + code + '.png';
    }
    
    viewProduct(productCode: string) {
        this._router.navigate(['/product', productCode])
    }

    displayWishlistItems(code) {
        return this.wishlist.includes(code);
    }

    addtoWishlist(code) {
        this.wishlistService.addToWishlist(code);
    }
    //
    removefromWishlist(code) {
        this.wishlistService.removeFromWishlist(code);
    } 
}
