import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class GetDataService {

    //define variables

    root_url = 'https://showaasia.pbg.com.hk/api';
    targeted_url = this.root_url;
    graphics_url = 'https://storage.googleapis.com/showaasia-pictures/products'

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type':  'application/json',
            'Authorization': 'my-auth-token'
        })
    };

    // finish defining variables

    constructor( 
    private http: HttpClient
    ) { }

    getRoot() {
        return this.graphics_url;    
    }

    get_all_products() {
        return this.http.get( this.root_url + "/products/get-all-active", this.httpOptions );
    }

    get_products(): Observable<any> {
        return this.http.get<any>(this.root_url + "/products/get-all-active", this.httpOptions );
    }
    
    get_products_internal(): Observable<any> {
        return this.http.get<any>(this.root_url + "/products/get-all", this.httpOptions );
    }

    get_list_of_products(product_list) {
        return this.http.post( this.root_url + "/products/get-list-products", product_list, this.httpOptions );
    }

    update_product(product) {
        return this.http.post( this.root_url + "/products/update", product, this.httpOptions );
    }

    getAllCatList() {
        return this.http.get( this.root_url + "/products/get-allCatList", this.httpOptions );
    }

    getSubCatList(subCat) {
        return this.http.post( this.root_url + "/products/get-subCatList-specificMainCat", subCat, this.httpOptions );
    }

    // message related--
    submit_message(msg) {
        return this.http.post( this.root_url + "/message/add", msg, this.httpOptions );
    }

    // wishlist related--
    submit_wishlist(wishlist) {
        return this.http.post( this.root_url + "/enquiry/add", wishlist, this.httpOptions );
    }

    // content updates related--
    get_web_content(content) {
        return this.http.post( this.root_url + "/webContent/get", content, this.httpOptions );
    }
    
    get_pp() {
        return this.http.get( this.root_url + '/webContent/get-pp',  this.httpOptions );
    };

    get_tnc() {
        return this.http.get( this.root_url + '/webContent/get-tnc',  this.httpOptions );
    };

    // newsletter subscription related--
    subscribe_newsletter(subscriber) {
        return this.http.post( this.root_url + "/subscribers/add", subscriber, this.httpOptions );
    }

    // page_visited related--
    visit_page_record(page) {
        return this.http.post( this.root_url + "/visitors/add-record", page, this.httpOptions );
    }
}
