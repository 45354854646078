import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

//Customised services
import { GetDataService } from '../get-data.service';
import { ProductsService } from '../products.service';
import { WishListService } from '../wish-list.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

    // Translation declaration
    lang;
    subscription: Subscription;
    
    // start of variables declaration
    all_products;
    searchTerm: string;
    searchTermZh: string;
    wishlist;
    graphics_url = this.dataService.getRoot();
    // end of variables declaration

    constructor( public dataService: GetDataService, public productsService: ProductsService, public wishlistService: WishListService, private translate: TranslateService, public LangService: LanguageService, private _router: Router ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item);
        
        this.dataService.get_products().subscribe(data=>{
            this.all_products = data;
        })
        this.all_products = this.productsService.getAllProducts();
        this.wishlist = this.wishlistService.getWishlist();
    }
    
    viewProduct(productCode: string) {
        this._router.navigate(['/product', productCode])
    }
    
    viewCategory(productCat: string) {
        this._router.navigate(['/products', productCat])
    }
    
    get_product_small_photo(code) {
        return this.graphics_url + '/small_size/' + code + '.png';
    }
    
    get_wholesale_price(product) {
        return product.wholesale_unit_price * product.wholesale_min_order_quantity
    }
    
     
    displayWishlistItems(code) {
        return this.wishlist.includes(code);
    }

    addtoWishlist(code) {
        this.wishlistService.addToWishlist(code);
    }
//
    removefromWishlist(code) {
        this.wishlistService.removeFromWishlist(code);
    } 

}
