// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    min-height: 80vh;
}

.cloud_icon {
    min-width: 1.5em;
    height: 1.5em;
}

.error_message_class {
    color: red;
    font-size: 0.7em;
    font: Roboto;
    padding: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/app/contact-us/contact-us.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,YAAY;AAChB","sourcesContent":["body {\n    min-height: 80vh;\n}\n\n.cloud_icon {\n    min-width: 1.5em;\n    height: 1.5em;\n}\n\n.error_message_class {\n    color: red;\n    font-size: 0.7em;\n    font: Roboto;\n    padding: 1vw;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
