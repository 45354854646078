import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';

//Customised services
import { GetDataService } from '../get-data.service';
import { ProductsService } from '../products.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

// Angular Materials
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
    // Translation declaration
    lang;
    subscription: Subscription;
    
    msg;
    submitMsgForm: UntypedFormGroup;

    constructor( public dataService: GetDataService, private fb: UntypedFormBuilder, public productsService: ProductsService, private translate: TranslateService, public LangService: LanguageService ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)
        
          this.submitMsgForm = this.fb.group({
            title: ['', [Validators.required]],
            name: ['', [Validators.required]],
            contact_num: [''],
            email: [''],
            company_name: [''],
            message: ['', [Validators.required]]
        })
    }
    
    submitMessage() {
        this.dataService.submit_message(this.submitMsgForm.value).subscribe(data=>{
            this.msg = data;
            if (this.msg.code == 'S') {
                setTimeout( ()=> { alert("Thanks for letting us know! We'll get back to you shortly."); window.location.reload(), 300 })
            }
        })   
    }

}
