import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';

//Customised services
import { GetDataService } from '../get-data.service';
import { ProductsService } from '../products.service';

// Translation related
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService} from '../language.service';

// Angular Materials
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-privacy-page',
    templateUrl: './privacy-page.component.html',
    styleUrls: ['./privacy-page.component.css']
})
export class PrivacyPageComponent implements OnInit {

    // start of variables declaration
    all_products;
    dataSource;
    selectedProduct;
    htmlContent;
    htmlContentZH;
    htmlContentEN;
    // end of variables declaration

    // Translation declaration
    lang;
    subscription: Subscription;

    updateProductForm: UntypedFormGroup;

    constructor( public _dataService: GetDataService, private fb: UntypedFormBuilder, public productsService: ProductsService, private translate: TranslateService, public LangService: LanguageService ) { }

    ngOnInit() {
        this.subscription = this.LangService.navItem$.subscribe(item => this.lang = item)

        this._dataService.get_products_internal().subscribe(data=>{
            this.all_products = data;
            this.dataSource = new MatTableDataSource(this.all_products);
            // this.dataSource.paginator = this.paginator;
        });
        
        this._dataService.get_pp().subscribe(data => {
            this.htmlContent = data[0];
            this.htmlContentZH = this.htmlContent.content.zh;
            this.htmlContentEN = this.htmlContent.content.en;
        });
        
        this.updateProductForm = this.fb.group({
            product_id: ['', [Validators.required]],
            product_name: this.fb.group ({
                en: ['', [Validators.required]],
                zh: ['', [Validators.required]],
            }),
            brand_name: this.fb.group ({
                en: ['', [Validators.required]],
                zh: ['', [Validators.required]],
            }),
            address: this.fb.group ({
                en: ['', [Validators.required]],
                zh: ['', [Validators.required]],
            }),
            retail_order_quantity: ['', [Validators.required]],
            retail_order_unit: ['', [Validators.required]],
            wholesale_unit_price: ['', [Validators.required]],
            weight_unit: ['', [Validators.required]],
            weight: ['', [Validators.required]],
            country_of_origin: ['', [Validators.required]],
            city_of_origin: ['', [Validators.required]],
            mainCatCode: ['', [Validators.required]],
            subCatCode: ['', [Validators.required]],
            public_status: ['', [Validators.required]]
        })
    }

    // displayedColumns: string[] = ['product_code', 'product_name', 'brand_name', 'country_of_origin', 'city_of_origin', 'subCatCode', 'weight', 'weight_unit', 'retail_order_quantity', 'retail_order_unit', 'retail_unit_price'];

    // showDetails(product){
    //     this.selectedProduct = product;
    //     this.updateProductForm.patchValue({
    //         product_id: product.product_id,
    //         product_name: {
    //             en: product.product_name.en,
    //             zh: product.product_name.zh,
    //         },
    //         brand_name: {
    //             en: product.brand_name.en,
    //             zh: product.brand_name.zh,
    //         },
    //         retail_order_quantity: product.retail_order_quantity,
    //         retail_order_unit: product.retail_order_unit,
    //         weight: product.weight,
    //         weight_unit: product.weight_unit,
    //         wholesale_unit_price: product.wholesale_unit_price,
    //         country_of_origin: product.country_of_origin,
    //         city_of_origin: product.city_of_origin,
    //         mainCatCode: product.mainCatCode,
    //         subCatCode: product.subCatCode,
    //         public_status: product.public_status
    //     })
    // }

    // updateProduct(){
    //     this._dataService.update_product(this.updateProductForm.value).subscribe(data=>{ 
    //     })
    //     setTimeout( () => { alert('Update product successfully.'); window.location.reload(), 100}); 
    // }

    // @ViewChild(MatPaginator, null) paginator: MatPaginator;

}
