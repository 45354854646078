// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  min-height: 50vh;
}

.card-img-top {
  height: 20vh;
}
.card-photo {
  max-height: 100%;
  max-width: 100%;
}

.pointerCursor {
  cursor: pointer;
}

.wishlisticon {
}

.wishlisticon:hover {
  transform: scale(1.5, 1.5);
}

`, "",{"version":3,"sources":["webpack://./src/app/products/products.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;AACA;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".card {\n  min-height: 50vh;\n}\n\n.card-img-top {\n  height: 20vh;\n}\n.card-photo {\n  max-height: 100%;\n  max-width: 100%;\n}\n\n.pointerCursor {\n  cursor: pointer;\n}\n\n.wishlisticon {\n}\n\n.wishlisticon:hover {\n  transform: scale(1.5, 1.5);\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
