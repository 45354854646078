// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    min-height: 50vh;
}

.card-img-top{
    height: 20vh;    
}

.card-photo {
    max-height: 100%;
    max-width: 100%;
}

.wave_icon {
    height: 100%;
}

.navigation-item {
    border-left: 2px solid #DBDADA;
    border-right: 2px solid #DBDADA;
}`, "",{"version":3,"sources":["webpack://./src/app/products/products-category.component.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,8BAA8B;IAC9B,+BAA+B;AACnC","sourcesContent":[".card {\n    min-height: 50vh;\n}\n\n.card-img-top{\n    height: 20vh;    \n}\n\n.card-photo {\n    max-height: 100%;\n    max-width: 100%;\n}\n\n.wave_icon {\n    height: 100%;\n}\n\n.navigation-item {\n    border-left: 2px solid #DBDADA;\n    border-right: 2px solid #DBDADA;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
